.heading {
  margin: 0 0 1.5rem;
  color: var(--leme-color-gray-9);
  font: var(--leme-typography-body-medium);
}

.heading span {
  color: var(--leme-color-brand-light);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.desktopCard {
  display: none;
}

.mobileCard {
  display: block;
  padding-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .desktopCard {
    display: block;
    padding-bottom: 1.5rem;
  }
  .mobileCard {
    display: none;
    font-size: 0.875rem;
  }
}