.books {
  margin-top: 21px;
  margin-bottom: 48px;
}

@media (max-width: 576px) {
  .books {
    margin-top: 24px;
    margin-bottom: 30px;
  }
}

.books > .MuiPaper-root {
  width: 100% !important;
  max-width: 216px;
}

.colorText {
  color: var(--text-primary);
  font-size: 14px;
}

.root {
  margin-top: 24px;
  margin-right: 24px;
}

.galleryBooks {
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}

@media (max-width: 480px) {
  .galleryBooks {
    gap: 12px;
  }
}

.galleryBooks > .MuiCard-root {
  margin: 0 !important;
  width: calc(25% - 18px) !important;
  min-width: 200px;
  max-width: 216px;
}

@media (max-width: 769px) {
  .galleryBooks > .MuiCard-root {
    width: calc(33% - 16px) !important;
    min-width: 200px;
    max-width: 216px;
  }
}

@media (max-width: 480px) {
  .galleryBooks > .MuiCard-root {
    width: calc(50% - 12px) !important;
    min-width: 156px;
    max-width: 176px;
  }
}

@media (max-width: 376px) {
  .galleryBooks > .MuiCard-root {
    width: calc(50% - 12px) !important;
    min-width: 128px;
    max-width: 156px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

@media (max-width: 576px) {
  .pagination {
    margin: 0 auto;
    margin-top: 20px;
    width: 268px;
  }
}

@media (max-width: 348px) {
  .pagination {
    width: 94%;
  }
}

.notificationRoot {
  margin-top: 88px;
  margin-right: 8px;
}

.desktopCard {
  display: none;
}

.mobileCard {
  display: block;
  padding-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .desktopCard {
    display: block;
    padding-bottom: 1.5rem;
  }
  .mobileCard {
    display: none;
    font-size: 0.875rem;
  }
}

.header {
  display: 'flex';
  justify-content: 'space-between';
  align-items: 'flex-end';
  margin-bottom: 6px;
}

@media (max-width: 576px) {
  .header {
    margin-bottom: 14px;
  }
}

.search-result-text {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(33, 37, 41, 1);
}

@media (max-width: 576px) {
  .search-result-text {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 32px;
  }
}

.list-by-label {
  display: block;
  font-size: 14px;
  color: rgba(73, 80, 87, 1);
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-top: 24px;
}

.recent-opened {
  margin-top: 16px;
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.15px;
  color: rgba(33, 37, 41, 1);
}

@media (max-width: 576px) {
  .recent-opened {
    margin-top: 32px;
    font-size: 16px;
    line-height: 1.5;
  }
}

.divider {
  margin-bottom: 24px;
}

@media (max-width: 1024px) {
  .divider {
    margin-bottom: 24px;
  }
}

@media (max-width: 769px) {
  .divider {
    margin-bottom: 48.5px;
  }
}

@media (max-width: 426px) {
  .divider {
    margin-bottom: 24px;
  }
}

@media (max-width: 348px) {
  .divider {
    margin-bottom: 24px;
  }
}