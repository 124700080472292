.searchClearButton {
  width: fit-content;
  right: 10px;
}

.clearButton {
  background: transparent;
  border: 0;
  color: var(--leme-color-gray-6);
  font: var(--leme-typography-body-small-medium);
  cursor: pointer;
}

.searchForm {
  width: 100%;
}

.searchRoot {
  display: inline-grid;
  align-content: center;
  width: 100%;
  border: 0;
}

.searchInput {
  border: none;
  padding-left: 44px !important;
  padding-right: 44px !important;
  background: var(--leme-color-gray-1);
  color: var(--leme-color-gray-6);
  border-radius: var(--leme-rounded-md);
  height: 40px;
  font: var(--leme-typography-body-small-regular);
  transition: none;
}

.searchInput::placeholder {
  color: var(--leme-color-gray-6);
}

.searchInput:active {
  border: 0;
}

.searchInput:focus {
  box-shadow: 0 4px 17.11px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid var(--leme-color-gray-3);
  color: var(--leme-color-gray-9);
}

.searchIcon,
.clearButton span {
  height: 18px;
  width: 18px;
}

.searchIcon {
  left: 15px;
  top: 10px;
}

.profileLink {
  color: var(--leme-color-gray-9);
  font: var(--leme-typography-body-small-medium);
  padding: 8px 16px;
  display: block;
  cursor: pointer;
}

.profileLink:hover {
  background-color: var(--leme-color-gray-1);
  text-decoration: none;
}