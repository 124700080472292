.description {
    font-family: var(--leme-typography-family);
    font-weight: var(--leme-typography-weight-medium);
    max-width:800px;
    width: 100%;
    margin: 0 auto 32px;
}

.cardsGroup {
    width: 100%;
    flex-direction: column;
    gap: 32px;
}

.card {
    border-radius: var(--leme-rounded-lg);
    max-width:800px;
    width: 100%;
    height: 181px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 24px;
}

.cardText {
    font-family: var(--leme-typography-family);
    font-size: 40px;
    font-weight: 600;
    line-height: 56px;
    color: var(--leme-color-gray-0);
}

@media (max-width: 768px) {
    .card {
        width: 100%;
        height: 100px;
    }

    .cardText {
        font-size: 24px;
        line-height: 32px;
    }
}
