.dialog {
    border: 0;
    border-radius: var(--leme-rounded-xl);
    background-color: var(--leme-color-gray-0);
    max-width: 600px;
    padding: 2rem;
    width: 100%;
    display: grid;
    gap: 2rem;
    height: calc(100% - 80px);
    align-content: start;
    margin-right: 0;
    right: 40px;
}

.dialogMobile {
    border: 0;
    background-color: var(--leme-color-gray-0);
    max-width: 100%;
    max-height: 100%;
    padding: 2rem;
    width: 100%;
    display: grid;
    gap: 2rem;
    align-content: start;
}


.dialog::backdrop {
    background-color: var(--leme-color-backdrop);
}

.dialogHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialogHeaderMobile {
    display: flex;
    align-items: center;
}

.dialogHeader h2 {
    font: var(--leme-typography-body-semibold);
    margin: 0;
}

.tabsContainer {
    padding-bottom: 2rem;
}

.referencesContainer {
    display: grid;
    gap: 1rem;
}

.referencesContainer button {
    width: fit-content;
}

.mobileBottomActions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 1.5rem; 
    display: flex;
    justify-content: center;
    align-items: center;
  }