.title {
  font: var(--leme-typography-body-small-semibold);
  color: var(--leme-color-gray-9);
}

.authors, .edition {
  font: var(--leme-typography-body-extra-small-medium);
  color: var(--leme-color-gray-7);
}

.alertMessage {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}