.button {
  border-radius: var(--leme-rounded-md);
  background-color: transparent;
  cursor: pointer;
  border: 0;
  display: inline-flex;
  align-items: center;
  padding: 10px;
}

.button[data-variant="lg"] {
  width: 40px;
  height: 40px;
}

.button[data-variant="sm"] {
  width: 24px;
  height: 24px;
}

.button:hover {
  background-color: var(--leme-color-gray-0);
  box-shadow: var(--leme-shadow);
}

.button:disabled .heartIcon {
  color: var(--leme-color-gray-5);
}

.heartIconFilled {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  color: red;
}

.heartIcon {
  color: var(--leme-color-gray-9);
}