.title {
  margin-bottom: 48px;
  font: var(--leme-typography-heading-4-semibold);
  color: var(--leme-color-gray-9);
}

.pagination {
  margin-top: 28px;
}

.emptyResultTitle {
  font: var(--leme-typography-body-semibold);
  color: var(--leme-color-gray-9);
}

.emptyResultSubtitle {
  font: var(--leme-typography-body-small-regular);
  color: var(--leme-color-gray-9);
}

.emptyResultIconContainer {
  border: 1px solid var(--leme-color-gray-3);
  border-radius: var(--leme-rounded-md);
  padding: 4px;
}

.emptyResultIcon {
  color: var(--leme-color-gray-7);
}

.emptyResultButton {
  width: 36%;
  font: var(--leme-typography-body-small-semibold);
  color: var(--leme-color-gray-9);
  border-radius: var(--leme-rounded-md);
  border: 1px solid var(--leme-color-gray-7);
}
